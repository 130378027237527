<template>
	<Layout>
		<Header :bordered="false" :animated="animate" />
		<div class="dashboard" :class="{ 'animated': animate && !loading }">
			<b-loading :is-full-page="false" v-model="loading"></b-loading>
			<b-carousel-list v-if="overview.length > 0" animated="slide" :data="overview" :has-drag="true" :items-to-show="isDesktop ? 5 : isTablet ? 4 : 2">
				<template #item="overview">
					<div class="dashboard__box px-2 pt-3 pb-5">
						<h4 class="dashboard__box__value">
							{{ overview.value }}
						</h4>
						<span class="dashboard__box__type">{{ $t(overview.type) }}</span>
					</div>
				</template>
			</b-carousel-list>
			<span v-else class="dashboard__dashboard placeholder">
				<div v-for="i in blocks" :key="i" class="dashboard__box placeholder">
					<b-skeleton width="50" height="60"></b-skeleton>
					<b-skeleton :width="`${randomSize()}%`" height="20"></b-skeleton>
				</div>
			</span>
			<section class="columns is-multiline is-mobile mt-3">
				<article class="column is-12-mobile">
					<div class="dashboard__panel dashboard__list scroll">
						<ul v-if="places.length > 0">
							<li v-for="(p, i) in places" :key="p.id">
								<a @click="active = i" :class="{ active: active == i }">
									<span>{{ p.name }}</span>
									<div class="status" :class="p.online ? 'online' : 'offline'"></div>
								</a>
							</li>
						</ul>
						<ul v-else>
							<li v-for="i in 6" :key="i" class="py-2">
								<span class="placeholders">
									<b-skeleton :width="`${randomSize()}%`" height="30"></b-skeleton>
									<div class="bullet">
										<b-skeleton width="20" height="20"></b-skeleton>
									</div>
								</span>
							</li>
						</ul>
					</div>
				</article>
				<article class="column is-12-mobile is-two-thirds-desktop">
					<div class="dashboard__panel dashboard__preview">
						<div v-if="places.length > 0" class="is-flex is-justify-content-space-between is-align-items-center mb-5">
							<h2 class="title is-semibold is-size-4-touch is-size-3 mb-0">{{ places[active].name }}</h2>
							<span @click="openScreen()">
								<svg-icon icon="external" class="icon is-small"></svg-icon>
							</span>
						</div>
						<b-tabs v-if="places.length > 0" v-model="active">
							<b-tab-item v-for="(p, i) in places" :key="`place2-${i}`">
								<iframe class="iframe" :src="getUrlPreview(p.code, false)"></iframe>
								<span class="stats">
									<div class="dashboard__box box px-4 pt-1 pb-3">
										<h4 class="dashboard__box__value">{{ p.views ? p.views.views_day : 0 }}</h4>
										<span class="dashboard__box__type type">
											{{ $t('views_day') }}
										</span>
									</div>
									<div class="dashboard__box box px-4 pt-1 pb-3 mb-0">
										<h4 class="dashboard__box__value">{{ p.views ? p.views.views_month : 0 }}</h4>
										<span class="dashboard__box__type type">
											{{ $t('views_month') }}
										</span>
									</div>
								</span>
							</b-tab-item>
						</b-tabs>
					</div>
				</article>
			</section>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { responsive } from '@/mixins/responsive'
import Api from '@/services/api'
import { errorToast } from '@/mixins/toast'
import server from '@/mixins/server'
import '@/mixins/generic'

export default {
	name: 'Dashboard',
	mixins: [
		responsive,
		server
	],
	components: {
		Layout,
		Header,
		'svg-icon': Icon
	},
	data() {
		return {
			blocks: 0,
			active: 0,
			animate: false,
			loading: false,
			bordered: true,
			places: [],
			overview: []
		}
	},
	mounted() {
		this.blocks = this.isMobile ? 2 : 5
		this.getOverview()
		this.getPlaces()
		eventHub.$on('animate-dashboard', () => {
			this.animate = true
		})
		setInterval(() => {
			let 
				next = this.active + 1,
				places = this.places.length,
				active = next >= places ? 0 : next
			this.active = active
		}, 10000)
	},
	methods: { 
		getOverview() {
			Api.get('dashboard/overview')
				.then(({ data }) => {
					this.overview = data
				})
				.catch((err) => {
					console.log(err)
				})
		},
		getPlaces() {
			Api.get('place/findAll')
                .then(({ data }) => {
					this.places = data
                })
                .catch((err) => {
                    console.log(err)
                    errorToast('Ocorreu um erro ao buscar os locais')
                })
		},
		openScreen() {
			window.open(this.getUrlPreview(this.places[this.active].code), '_blank').focus()
		}
	}
}
</script>
